// import { toast } from "sonner";

//base URl set dynamic
export const baseURL = "https://ankitgoswami.pythonanywhere.com";

//data edit and set form values function
export const handleEdit = (row, form, setEditData, open) => {
  setEditData(row?.original?.dhaja_id);
  open();
  // form?.setFieldValue("mobile_number", Number(row?.original?.mobile_number));
  form?.setFieldValue("date_of_dhaja", new Date(row?.original?.date_of_dhaja));
  form?.setFieldValue("name", row?.original?.name);
  form?.setFieldValue("dhaja_number", row?.original?.dhaja_number);
  form?.setFieldValue(
    "is_payment_received",
    String(row?.original?.is_payment_received)
  );

  form?.setFieldValue("total_dhaja", Number(row?.original?.total_dhaja));
  form?.setFieldValue("total_payment", Number(row?.original?.total_payment));
  // form?.setFieldValue("city", row?.original?.city);
  form?.setFieldValue("reference", row?.original?.reference);
  form?.setFieldValue("head_name", row?.original?.head_name);
  // form?.setFieldValue("sub_name", row?.original?.sub_name);
};

//Data search filter Functionality
export const fetchFilteredData = async (
  query,
  setFilteredData,
  val,
  param,
  condition,
  pagination
) => {
  let response = "";
  try {
    if (condition === "static" && param !== "StaticDhajaHeadName") {
      response = await fetch(
        `${baseURL}/get-all-details/?${val}=${query}&page=${
          pagination.pageIndex + 1
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } else if (condition === "search" && param !== "StaticDhajaHeadName") {
      response = await fetch(
        `${baseURL}/get-all-details/?${val}=${param}&name=${query}&page=${
          pagination.pageIndex + 1
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } else if (condition === "search" && param === "StaticDhajaHeadName") {
      response = await fetch(
        `${baseURL}/get-all-details/?name=${query}&page=${
          pagination.pageIndex + 1
        }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    }

    if (!response.ok) {
      throw new Error("Failed to fetch filtered data");
    }
    const data = await response.json();
    setFilteredData(data?.data);
  } catch (error) {
    console.error("Fetch filtered data error:", error);
  }
};

//first time data visible in table
export const viewData = async (setData, pagination) => {
  try {
    const response = await fetch(
      `${baseURL}/get-all-details/?page=${pagination.pageIndex + 1}`
      // Assuming the correct format is `baseURL/get-all-details?page=2` for pageIndex 1
    );
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    setData(data?.data);
  } catch (error) {
    // Handle error
    // setError(error.message);
  } finally {
    // Optionally handle loading state
    // setLoading(false);
  }
};

//first time data visible in table
export const firstDashBoardData = async (setMainDashBoard) => {
  try {
    const response = await fetch(`${baseURL}/get-head-data/`);
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    const data = await response.json();
    setMainDashBoard(data?.data);
  } catch (error) {
    // setError(error.message);
  } finally {
    // setLoading(false);
  }
};
