import { useMemo, useState, useEffect } from "react";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { fetchFilteredData } from "../common/commonFunction";
import ActionComponent from "./ActionComponent";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
const DataTable = ({
  data,
  viewData,
  open,
  form,
  setEditData,
  setData,
  setFilteredData,
  filteredData,
  pagination,
  setPagination,
}) => {
  const [globalFilter, setGlobalFilter] = useState("");
  const { param } = useParams();

  useEffect(() => {
    if (param && globalFilter === undefined) {
      if (param === "Rameshbhai Lavjibhai Dhaduk") {
        fetchFilteredData(
          "Rameshbhai Lavjibhai Dhaduk",
          setFilteredData,
          "head_name",
          param,
          "static",
          pagination
        );
      } else if (param === "General") {
        fetchFilteredData(
          "General",
          setFilteredData,
          "head_name",
          param,
          "static",
          pagination
        );
      } else if (param === "Mauleshbhai Dayabhai Ukani") {
        fetchFilteredData(
          "Mauleshbhai Dayabhai Ukani",
          setFilteredData,
          "head_name",
          param,
          "static",
          pagination
        );
      } else if (param === "StaticDhajaHeadName") {
        // setFilteredData(data);
        viewData(setFilteredData, pagination);
      }
    } else {
      setFilteredData(data);
    }
    if (globalFilter && param) {
      fetchFilteredData(
        globalFilter,
        setFilteredData,
        "head_name",
        param,
        "search",
        pagination
      );
    } else {
      if (!param) {
        setFilteredData(data);
      }
    }
  }, [
    globalFilter,
    data,
    param,
    setFilteredData,
    viewData,
    pagination.pageIndex,
    pagination
  ]);

  // Should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "date_of_dhaja",
        header: "Date Of Dhaja",
        Cell: ({ cell, row }) =>
          dayjs(row.original.date_of_dhaja).format("DD/MM/YYYY"),
      },
      {
        accessorKey: "dhaja_number",
        header: "Dhaja Number",
      },
      {
        accessorKey: "reference",
        header: "Reference",
      },
      // {
      //   accessorKey: "is_payment_received",
      //   header: "Is Payment Received",
      //   Cell: ({ cell, row }) =>
      //     cell.getValue() === true &&
      //     String(row?.original?.is_payment_received),
      // },
      {
        accessorKey: "head_name",
        header: "Head Name",
      },
      {
        accessorKey: "total_dhaja",
        header: "Total Dhaja",
      },
      {
        accessorKey: "total_payment",
        header: "Total Payment",
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns,
    data: filteredData?.dhaja_data || [],
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <>
        <ActionComponent
          row={row}
          form={form}
          setEditData={setEditData}
          open={open}
          viewData={viewData}
          setFilteredData={setFilteredData}
          setData={setData}
          pagination={pagination}
          param={param}
        />
      </>
    ),
    enableSorting: false,
    enableColumnActions: false,
    onGlobalFilterChange: setGlobalFilter,
    state: { globalFilter, pagination },
    positionGlobalFilter: "left",
    initialState: {
      showGlobalFilter: true,
    },
    onPaginationChange: setPagination,
    rowCount: filteredData?.pagination?.total_records,
    manualPagination: true,
    mantinePaginationProps: {
      showRowsPerPage: false,
    },
    // paginationDisplayMode: "pages",
  });
  return (
    <div className="container">
      <MantineReactTable table={table} />
    </div>
  );
};
export default DataTable;
