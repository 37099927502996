import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  NumberInput,
  TextInput,
  Select,
} from "@mantine/core";
import { DatePickerInput, MonthPickerInput } from "@mantine/dates";
import dayjs from "dayjs";
import { IconCalendarEvent, IconReload } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { baseURL } from "../common/commonFunction";
import { toast } from "sonner";

const AddDhajaDataForm = ({
  opened,
  close,
  open,
  form,
  editData,
  viewData,
  setData,
  setFilteredData,
  pagination,
}) => {
  const [filterDate, setFilterDate] = useState(null);

  const navigate = useNavigate();
  const { param } = useParams();
  const headName = "head_name";

  const handleSubmit = async (values) => {
    const formattedValues = {
      ...values,
      total_payment: String(form?.values?.total_dhaja * 25000),
      date_of_dhaja: values.date_of_dhaja
        ? dayjs(values.date_of_dhaja).format("YYYY-MM-DD")
        : null,
    };

    try {
      let url = `${baseURL}/get-all-details/`;
      let method = "POST";

      if (editData !== null) {
        url = `${baseURL}/update-details/${editData}/`;
        method = "PUT";
      }

      const response = await fetch(url, {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formattedValues),
      });

      if (!response.ok) {
        throw new Error("Failed to submit data");
      }
      form.reset();
      close(); // Close modal or perform other actions
      toast.success(
        editData !== null
          ? "Data Updated SuccessFully"
          : "Add Data successfully"
      );
      if (param !== "StaticDhajaHeadName") {
        const newResponse = `${baseURL}/get-all-details/?${headName}=${param}&page=${
          pagination.pageIndex + 1
        }`;
        try {
          const response = await fetch(newResponse, {
            method: "GET", // or POST based on your API
            headers: {
              "Content-Type": "application/json",
            },
          });
          const data = await response.json();
          setFilteredData(data?.data);
          // Process the response as needed
        } catch (error) {
          console.error("Error fetching additional data:", error.message);
          // Handle error
        }
      } else {
        viewData(setFilteredData, pagination);
      }
      if (editData !== null && param === "StaticDhajaHeadName") {
        viewData(setFilteredData, pagination);
      }
    } catch (error) {
      console.error("Error submitting data:", error.message);
      // Handle error, e.g., show error message to the user
    }
  };

  const fetchData = async (filterDate) => {
    try {
      const formattedDate = filterDate
        ? dayjs(filterDate).format("YYYY-MM")
        : "";
      let url = `${baseURL}/get-all-details/?date_of_dhaja=${formattedDate}`;

      if (param !== "StaticDhajaHeadName") {
        url = `${baseURL}/get-all-details/?${headName}=${param}&date_of_dhaja=${formattedDate}`;
      }
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      setFilteredData(data?.data);
      // setData(data?.data?.dhaja_data);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleFilterDateChange = (date) => {
    setFilterDate(date);
    fetchData(date);
  };

  const labelProps = {
    style: { fontWeight: "bold" },
  };

  useEffect(() => {
    if (editData !== null && form?.values?.sub_name !== "") {
      form?.setFieldValue("is_sub_name", "true");
    } else {
      form?.setFieldValue("is_sub_name", "false");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  const formatDate = (date) => dayjs(date).format("YYYY-MM-DD");
  const parseDate = (dateString) =>
    dateString ? dayjs(dateString).toDate() : null;

  const onCloseFunction = () => {
    form.reset();
    close();
  };

  const restartData = async () => {
    if (param !== "StaticDhajaHeadName") {
      const newResponse = `${baseURL}/get-all-details/?${headName}=${param}&page=${
        pagination.pageIndex + 1
      }`;
      try {
        const response = await fetch(newResponse, {
          method: "GET", // or POST based on your API
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setFilteredData(data?.data);
        setFilterDate(null);
        // Process the response as needed
      } catch (error) {
        console.error("Error fetching additional data:", error.message);
        // Handle error
      }
    } else {
      viewData(setFilteredData, pagination);
      setFilterDate(null);
    }
  };

  return (
    <>
      <div className="flex justify-between mt-5 mb-3 container">
        <Modal
          opened={opened}
          onClose={() => onCloseFunction()}
          title={editData !== null ? "Update Dhaja Data" : "Add Dhaja"}
          closeOnClickOutside={false}
          size="lg" // Example of setting a responsive size
        >
          <form onSubmit={form.onSubmit(handleSubmit)} className="p-4">
            <TextInput
              label="Enter Full Name"
              withAsterisk
              className="mb-4"
              placeholder="Enter Full Name"
              {...form.getInputProps("name")}
              labelProps={labelProps}
            />
            <DatePickerInput
              label="Enter Date Of Dhaja"
              className="mb-4"
              placeholder="Pick date"
              value={
                form.values.date_of_dhaja
                  ? formatDate(form.values.date_of_dhaja)
                  : null
              }
              onChange={(date) =>
                form.setFieldValue(
                  "date_of_dhaja",
                  date ? parseDate(date) : null
                )
              }
              error={form.errors.date_of_dhaja}
              {...form.getInputProps("date_of_dhaja")}
              labelProps={labelProps}
              valueFormat="DD/MM/YYYY"
            />
            {/* <NumberInput
              label="Enter Mobile Number"
              className="mb-4"
              placeholder="Enter Mobile Number"
              {...form.getInputProps("mobile_number")}
              labelProps={labelProps}
            /> */}

            <Select
              label="Enter Dhaja Number"
              className="mb-4"
              withAsterisk
              placeholder="Select Dhaja Number"
              data={[
                { value: "1", label: "1" },
                { value: "2", label: "2" },
                { value: "3", label: "3" },
                { value: "4", label: "4" },
                { value: "5", label: "5" },
                { value: "6", label: "6" },
              ]}
              {...form.getInputProps("dhaja_number")}
              labelProps={labelProps}
            />
            <Select
              label="Select Refrence"
              className="mb-4"
              withAsterisk
              placeholder="Select Refrence"
              data={[
                { value: "VP", label: "VP" },
                { value: "HP", label: "HP" },
                { value: "NT", label: "NT" },
                { value: "KT", label: "KT" },
                { value: "PT", label: "PT" },
                { value: "VT", label: "VT" },
              ]}
              {...form.getInputProps("reference")}
              labelProps={labelProps}
            />

            <Select
              label="Select Head"
              className="mb-4"
              withAsterisk
              placeholder="Select Head"
              data={[
                {
                  value: "Rameshbhai Lavjibhai Dhaduk",
                  label: "Rameshbhai Lavjibhai Dhaduk",
                },
                { value: "General", label: "General" },
                {
                  value: "Mauleshbhai Dayabhai Ukani",
                  label: "Mauleshbhai Dayabhai Ukani",
                },
              ]}
              {...form.getInputProps("head_name")}
              labelProps={labelProps}
            />
            <NumberInput
              label="Enter Total Dhaja"
              className="mb-4"
              withAsterisk
              placeholder="Enter Total Dhaja"
              {...form.getInputProps("total_dhaja")}
              labelProps={labelProps}
              disabled
            />

            {/* <Text className="font-bold">Is Payment Received?</Text>
            <Radio.Group
              {...form.getInputProps("is_payment_received")}
              className="flex mb-4"
            >
              <Radio value="true" label="Yes" />
              <Radio value="false" label="No" />
            </Radio.Group> */}
            {/* {form?.values?.is_payment_received === "" && ( */}
            <NumberInput
              disabled
              label="Total Payment"
              withAsterisk
              value={form?.values?.total_dhaja * 25000}
              placeholder="Enter Total Payment"
              // {...form.getInputProps("total_payment")}
              labelProps={labelProps}
            />
            {/* )} */}

            <Box className="flex justify-end mt-4">
              {editData !== null ? (
                <Button type="submit">Update Data</Button>
              ) : (
                <Button type="submit">Save Data</Button>
              )}
            </Box>
          </form>
        </Modal>

        <div className="flex flex-col sm:flex-row justify-between w-full gap-4">
          <Button onClick={() => navigate("/dashboard")}>
            Back To Dashboard
          </Button>

          <div className="flex-1 max-w-xs relative">
            <div className="relative w-full">
              <MonthPickerInput
                value={filterDate}
                onChange={handleFilterDateChange}
                placeholder="Select a month"
                icon={<IconCalendarEvent />}
                className="w-full pb-2.5 pr-10" // Add padding to the right to make space for the close icon
              />
              {filterDate && (
                <IconReload
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  style={{ marginTop: "-5px" }}
                  onClick={
                    () => restartData()
                    // viewData(setFilteredData, pagination);
                    // setFilterDate(null);
                  }
                />
              )}
            </div>
          </div>
          <Button onClick={open}>Add Dhaja Data</Button>
        </div>
      </div>
    </>
  );
};

export default AddDhajaDataForm;
