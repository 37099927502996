import "./App.css";
import { MantineProvider } from "@mantine/core";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Login from "./pages/Login";
import DashBoard from "./components/DashBoard";
import MainDashBoard from "./components/MainDashBoard";
import DetailsPage from "./pages/DetailsPage";
import { Toaster } from "sonner";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/allData/:param", // Define dynamic parameter ':param'
      element: <DashBoard />,
    },
    {
      path: "/dashboard",
      element: <MainDashBoard />,
    },
    {
      path: "/details",
      element: <DetailsPage />,
    },
  ]);

  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        components: {
          Button: {
            defaultProps: {
              size: "md",
              variant: "filled",
              color: "orange",
            },
          },
          TextInput: {
            defaultProps: {
              size: "md",
            },
            styles: (theme) => ({
              label: { fontWeight: "bold" },
            }),
          },
          FileInput: {
            defaultProps: {
              size: "md",
            },
            styles: (theme) => ({
              label: { fontWeight: "bold" },
            }),
          },
          Select: {
            defaultProps: {
              size: "md",
            },
            styles: (theme) => ({
              label: { fontWeight: "bold" },
            }),
          },
          PasswordInput: {
            defaultProps: {
              size: "md",
            },
            styles: (theme) => ({
              label: { fontWeight: "bold" },
            }),
          },
          NumberInput: {
            defaultProps: {
              size: "md",
              hideControls: true,
            },
            styles: (theme) => ({
              label: { fontWeight: "bold" },
            }),
          },
          DatePickerInput: {
            defaultProps: {
              size: "md",
              hideControls: true,
            },
            styles: (theme) => ({
              label: { fontWeight: "bold" },
            }),
          },
        },
        fontFamily: "Inter Variable, sans-serif",
        headings: {
          fontFamily: "Inter Variable, sans-serif",
        },
        primaryColor: "primary-blue",
        primaryShade: 7,
        colors: {
          "primary-red": [
            "#fff1f2",
            "#ffdfe0",
            "#ffc5c7",
            "#ff9da1",
            "#ff656b",
            "#fe353d",
            "#ed1c24",
            "#c70e15",
            "#a41016",
            "#881418",
          ],
          "primary-blue": [
            "#f1f8fe",
            "#e2f0fc",
            "#bfe0f8",
            "#86c8f3",
            "#46abea",
            "#1e91d9",
            "#1072b9",
            "#F7851B",
            "#104e7c",
            "#134167",
          ],
        },
        defaultRadius: "md",
      }}
    >
      <RouterProvider router={router} />
      <Toaster
        position="top-right"
        duration={1800}
        richColors={true}
        closeButton
      />
    </MantineProvider>
  );
}

export default App;
