// // import React from "react";
// // import { useSearchParams } from "react-router-dom";

// // const DetailsPage = () => {
// //   const [searchParams] = useSearchParams();
// //   const name = searchParams.get("name");

// //   console.log("fileName:::::::::", name);
// //   return <div>DetailsPage</div>;
// // };

// // export default DetailsPage;

// import { useMemo, useState, useEffect } from "react";
// import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
// import { fetchFilteredData } from "../common/commonFunction";
// import ActionComponent from "../components/ActionComponent";

// const DetailsPage = ({ data, viewData, open, form, setEditData }) => {
//   const [globalFilter, setGlobalFilter] = useState("");
//   const [filteredData, setFilteredData] = useState(data);

//   useEffect(() => {
//     if (globalFilter) {
//       fetchFilteredData(globalFilter, setFilteredData);
//     } else {
//       setFilteredData(data);
//     }
//   }, [globalFilter, data]);

//   // Should be memoized or stable
//   const columns = useMemo(
//     () => [
//       {
//         accessorKey: "date_of_dhaja",
//         header: "date_of_dhaja",
//       },
//       {
//         accessorKey: "name",
//         header: "Name",
//       },
//       {
//         accessorKey: "mobile_number",
//         header: "Mobile Number",
//       },
//       {
//         accessorKey: "city",
//         header: "city",
//       },
//       {
//         accessorKey: "reference",
//         header: "reference",
//       },
//       {
//         accessorKey: "dhaja_number",
//         header: "Dhaja Number",
//       },
//       {
//         accessorKey: "is_payment_received",
//         header: "Is Payment Received",
//       },
//       {
//         accessorKey: "sub_name",
//         header: "sub_name",
//       },
//       {
//         accessorKey: "total_payment",
//         header: "Total Payment",
//       },
//       {
//         accessorKey: "total_dhaja",
//         header: "total_dhaja",
//       },
//     ],
//     []
//   );

//   const table = useMantineReactTable({
//     columns,
//     data: filteredData,
//     enableRowActions: true,
//     renderRowActions: ({ row }) => (
//       <>
//         <ActionComponent
//           row={row}
//           form={form}
//           setEditData={setEditData}
//           open={open}
//           viewData={viewData}
//         />
//       </>
//     ),
//     enableSorting: false,
//     enableColumnActions: false,
//     onGlobalFilterChange: setGlobalFilter,
//     state: { globalFilter },
//     positionGlobalFilter: "left",
//     initialState: {
//       showGlobalFilter: true,
//     },
//   });

//   return (
//     <div className="container">
//       <MantineReactTable table={table} />
//     </div>
//   );
// };

// export default DetailsPage;

import React from "react";

const DetailsPage = () => {
  return <div>DetailsPage</div>;
};

export default DetailsPage;
