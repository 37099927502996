import { ActionIcon, Box, Modal, Button, Text } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import React, { useState } from "react";
import { baseURL, handleEdit } from "../common/commonFunction";
import { toast } from "sonner";

const ActionComponent = ({
  row,
  form,
  setEditData,
  open,
  viewData,
  setFilteredData,
  setData,
  param,
  pagination,
}) => {
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const headName = "head_name";

  // const subDataTable = (row) => {
  //   fetchFilteredData(row, setFilteredData, "name");
  // };

  const confirmDelete = async () => {
    // handleDelete(rowToDelete, viewData, setData);
    try {
      const response = await fetch(
        `${baseURL}/delete-details/${row?.original?.dhaja_id}/`,
        {
          method: "DELETE",
        }
      );
      if (param !== "StaticDhajaHeadName") {
        const newResponse = `${baseURL}/get-all-details/?${headName}=${param}&page=${
          pagination.pageIndex + 1
        }`;
        try {
          const response = await fetch(newResponse, {
            method: "GET", // or POST based on your API
            headers: {
              "Content-Type": "application/json",
            },
          });
          const data = await response.json();
          setFilteredData(data?.data);
          // Process the response as needed
        } catch (error) {
          console.error("Error fetching additional data:", error.message);
          // Handle error
        }
      } else {
        viewData(setFilteredData, pagination);
      }
      if (!response.ok) {
        throw new Error("Failed to delete row");
      }
      toast.success("Data Delete successfully");
      setDeleteModalOpened(false);
      // viewData(setFilteredData,pagination);
    } catch (error) {
      console.error("Delete row error:", error);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
        <ActionIcon
          color="orange"
          onClick={() => {
            handleEdit(row, form, setEditData, open);
          }}
        >
          <IconEdit />
        </ActionIcon>
        <ActionIcon
          color="red"
          onClick={() => {
            // setRowToDelete(row);
            setDeleteModalOpened(true);
          }}
        >
          <IconTrash />
        </ActionIcon>
      </Box>

      <Modal
        opened={deleteModalOpened}
        onClose={() => setDeleteModalOpened(false)}
        title="Confirm Delete"
      >
        <Text>Are you sure you want to delete this item?</Text>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px",
            marginTop: "16px",
          }}
        >
          <Button variant="outline" onClick={() => setDeleteModalOpened(false)}>
            Cancel
          </Button>
          <Button color="red" onClick={confirmDelete}>
            Delete
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default ActionComponent;
