import React, { useEffect, useState } from "react";
import { Grid, Col, Card, Text, Button, Box, Image } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import DashBoardImage from "../assets/dashboard.jpg";
import { baseURL } from "../common/commonFunction";

const MainDashBoard = () => {
  const navigate = useNavigate();
  const [dhajaList, setDhajaList] = useState([]);

  const viewData = async () => {
    try {
      const response = await fetch(`${baseURL}/get-head-data/`);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();

      let allDhajaLists = [];
      const headData = data.data.head_data;

      for (const key in headData) {
        if (headData.hasOwnProperty(key)) {
          const dhajaListWithTotals = headData[key].dhaja_list.map((dhaja) => ({
            ...dhaja,
            total_dhaja: headData[key].total_dhaja,
            total_payment: headData[key].total_payment,
          }));
          allDhajaLists = allDhajaLists.concat(dhajaListWithTotals);
        }
      }

      // Filter out duplicates based on head_name
      const uniqueDhajaList = [
        ...new Map(
          allDhajaLists.map((item) => [item.head_name, item])
        ).values(),
      ];

      // Add the special card at the beginning
      uniqueDhajaList.unshift({ specialCard: true });

      setDhajaList(uniqueDhajaList);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    viewData();
  }, []);

  return (
    <Box
      className="container flex flex-col items-center bg-blue-50 min-h-screen py-10"
      style={{ backgroundColor: "#f0f4f8" }}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Image src={DashBoardImage} alt="Descriptive Alt Text" width={300} />
      </motion.div>
      <div className="flex justify-end w-full mt-4">
        <motion.div whileHover={{ scale: 1.1 }}>
          <Button onClick={() => navigate("/allData/StaticDhajaHeadName")}>
            Add Data
          </Button>
        </motion.div>
      </div>
      <Grid className="w-full mt-5 gap-4 cursor-pointer grid grid-cols-1 md:grid-cols-2">
        {dhajaList.length > 0 ? (
          dhajaList.map((dhaja, index) => (
            <Col key={index} className="w-full">
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <Card
                  shadow="sm"
                  padding="lg"
                  className={`hover:bg-blue-200 transition duration-300 ease-in-out ${
                    index === 0 ? "md:col-span-2" : ""
                  } flex flex-col justify-between h-full`}
                  onClick={() => {
                    if (dhaja.specialCard) {
                      navigate(`/allData/StaticDhajaHeadName`);
                    } else {
                      navigate(`/allData/${dhaja.head_name}`);
                    }
                  }}
                >
                  {dhaja.specialCard ? (
                    <>
                      <Text weight={700} size="lg">
                        All Dhaja Data
                      </Text>
                      <Text size="md" className="opacity-70">
                        Total Dhaja:{" "}
                        {dhajaList.reduce(
                          (total, dhaja) =>
                            total + (parseFloat(dhaja.total_dhaja) || 0),
                          0
                        )}
                      </Text>
                      <Text size="md" className="opacity-70">
                        Total Payment:{" "}
                        {dhajaList.reduce(
                          (total, dhaja) =>
                            total + (parseFloat(dhaja.total_payment) || 0),
                          0
                        )}
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text weight={700} size="lg">
                        {dhaja.head_name}
                      </Text>
                      <Text size="md" className="opacity-70">
                        Total Dhaja: {dhaja.total_dhaja}
                      </Text>
                      <Text size="md" className="opacity-70">
                        Total Payment: {dhaja.total_payment}
                      </Text>
                    </>
                  )}
                </Card>
              </motion.div>
            </Col>
          ))
        ) : (
          <Text>No data available</Text>
        )}
      </Grid>
    </Box>
  );
};

export default MainDashBoard;
