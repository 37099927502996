import React, { useState } from "react";
import AddDhajaDataForm from "./AddDhajaDataForm";
import { useDisclosure } from "@mantine/hooks";
import DataTable from "./DataTable";
import { useForm } from "@mantine/form";
import { viewData } from "../common/commonFunction";

const DashBoard = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [filteredData, setFilteredData] = useState(data);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });

  const form = useForm({
    initialValues: {
      date_of_dhaja: null,
      name: "",
      mobile_number: "",
      city: "",
      dhaja_number: "",
      reference: "",
      is_payment_received: "false",
      sub_name: "",
      total_payment: "",
      total_dhaja: 1,
      head_name: "",
    },

    validate: (values) => {
      const errors = {};

      // Name is required
      if (!values.name) {
        errors.name = "required";
      }

      // Date of Dhaja is required
      if (!values.date_of_dhaja) {
        errors.date_of_dhaja = "required";
      }

      // Dhaja number is required
      if (!values.dhaja_number) {
        errors.dhaja_number = "required";
      }

      // Reference is optional, but let's validate it if needed in future
      if (!values.reference) {
        errors.reference = "required";
      }
      // Reference is optional, but let's validate it if needed in future
      if (!values.head_name) {
        errors.head_name = "required";
      }
      return errors;
    },
  });

  return (
    <>
      <AddDhajaDataForm
        opened={opened}
        open={open}
        close={close}
        viewData={viewData}
        form={form}
        editData={editData}
        setData={setData}
        setFilteredData={setFilteredData}
        filteredData={filteredData}
        pagination={pagination}
        setPagination={setPagination}
      />
      <DataTable
        data={data}
        viewData={viewData}
        open={open}
        form={form}
        setEditData={setEditData}
        setData={setData}
        setFilteredData={setFilteredData}
        filteredData={filteredData}
        pagination={pagination}
        setPagination={setPagination}
      />
    </>
  );
};

export default DashBoard;
