import { Button, PasswordInput, TextInput } from "@mantine/core";
import React from "react";
import { motion } from "framer-motion";
import logo from "../assets/Logo.jpg";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate("/dashboard");
  };

  return (
    <div className="app-background">
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-primary-red-50 to-primary-blue-100">
        <motion.div
          className="w-full max-w-md"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="p-8 bg-white shadow-lg rounded-xl">
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2, duration: 0.5 }}
            >
              <img
                src={logo}
                alt="Logo"
                width="130px"
                className="mx-auto mb-5"
              />
            </motion.div>
            <form className="space-y-5" onSubmit={handleSubmit}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4, duration: 0.5 }}
              >
                <TextInput
                  label="User Id"
                  withAsterisk
                  placeholder="User Id"
                  name="username"
                  type="text"
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6, duration: 0.5 }}
              >
                <PasswordInput
                  label="Password"
                  withAsterisk
                  placeholder="Password"
                  name="password"
                />
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.8, duration: 0.5 }}
              >
                <Button
                  fullWidth
                  type="submit"
                  className="bg-primary-blue-500 hover:bg-primary-blue-600 text-white"
                >
                  Log In
                </Button>
              </motion.div>
            </form>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Login;
